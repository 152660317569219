import React from 'react'
import { Layout } from '../../components/Layout'

const ThanksPage = () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>Thank you!</h1>
          <p>We will get back to you as soon as possible</p>
        </div>
      </div>
    </section>
  </Layout>
)
export default ThanksPage;
